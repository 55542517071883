import axios from "axios";
import Disqus from "disqus-react";
// import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import Layout from "../components/Layout";

function BlogDetails(props) {
  const [content, setContent] = useState("");
  const [featuredImg, setFeaturedImg] = useState("");
  const [blogTitle, setBlogTitle] = useState("");
  const blogId = props.match.params.id;
  // const blogFile = props.match.params.title;
  // const fileName = `${blogFile}.md`;

  useEffect(() => {
    // import(`../blog/${fileName}`)
    //         .then(res => {
    //             fetch(res.default)
    //                 .then(res => res.text())
    //                 .then(res => setContent(res))
    //                 .catch(err => console.log(err));
    //         })
    //         .catch(err => console.log(err));
    axios.get(process.env.REACT_APP_API + `/blog/${blogId}`).then((response) => {
      setContent(response.data.blog.details);
      setFeaturedImg(response.data.blog.featured_image);
      setBlogTitle(response.data.blog.title);
    });
  });

  const disqusShortname = "devlinn"; //found in your Disqus.com dashboard
  const disqusConfig = {
    url: `https://devlinn.info/blogs/blog-details/${blogId}`, //Homepage link of this site.
    identifier: blogId,
    title: blogTitle,
  };
  return (
    <Layout>
      <Helmet>
        <title>Blog Details - React Personal Portfolio</title>
        <meta
          name="description"
          content="Blog Details Page"
        />
      </Helmet>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
            {/* <Markdown> */}
              {/* {ReactHtmlParser('<h3>'+blogTitle+'</h3>')} */}
              {ReactHtmlParser(content)}
            {/* </Markdown> */}
          <div className="mi-blog-details-comments mt-30">
            <Disqus.DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlogDetails;
