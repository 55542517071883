import React from "react";
import ProgressiveImage from 'react-progressive-image';
import { Link } from "react-router-dom";

function Blog(props) {
  const { id, featured_image, title, created_day, created_month } = props.data;
  const getShortMonth = month => {
    return month.slice(0, 3);
  };
  // const getNospaceTitle = filesource => {
  //   let tempArr = filesource.split('/');
  //   let fileName = tempArr[tempArr.length - 1];
  //   let getName = fileName.slice(0, -3);
  //   return getName;
  // };
  return (
    <div className="mi-blog">
      <div className="mi-blog-image">
        <Link to={`blogs/blog-details/${id}`}>
        <ProgressiveImage
          src={featured_image}
          placeholder="/images/blog-image-placeholder.png"
        >
          {src => <img src={src} alt={title} />}
        </ProgressiveImage>
        </Link>
        <div className="mi-blog-date">
          <span className="date">{created_day}</span>
          <span className="month">{getShortMonth(created_month)}</span>
        </div>
      </div>
      <div className="mi-blog-content">
        <h5>
          <Link to={`blogs/blog-details/${id}`}>
            {title}
          </Link>
        </h5>
      </div>
    </div>
  );
}

export default Blog;
